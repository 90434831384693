import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "input", "editButton"];

  connect() {
    this.originalName = this.nameTarget.innerText.trim();
  }

  enableEditing() {
    this.nameTarget.classList.add("hidden");
    this.inputTarget.classList.remove("hidden");
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length);

    this.inputTarget.addEventListener("keydown", this.handleKeydown.bind(this));

    this.editButtonTarget.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor" class="size-6">
        <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
      </svg>
    `;

    this.editButtonTarget.dataset.action = "click->raid#save";
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      this.save();
    } else if (event.key === "Escape") {
      this.cancelEditing();
    }
  }

  save() {
    const newName = this.inputTarget.value.trim();
    if (newName === "" || newName === this.originalName) {
      this.cancelEditing();
      return;
    }

    fetch(`/raids/${this.inputTarget.dataset.raidId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
      body: JSON.stringify({ raid: { raid_name: newName } }),
    })
      .then(response => response.json())
      .then(data => {
        this.nameTarget.innerText = data.raid_name;
        this.originalName = data.raid_name;
        this.cancelEditing();
      })
      .catch(error => console.error("Error:", error));
  }

  cancelEditing() {
    this.nameTarget.classList.remove("hidden");
    this.inputTarget.classList.add("hidden");
    this.inputTarget.value = this.originalName;

    this.editButtonTarget.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" class="size-6">
        <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1 0 32c0 8.8 7.2 16 16 16l32 0zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/>
      </svg>
    `;

    this.editButtonTarget.dataset.action = "click->raid#enableEditing";
  }
}
