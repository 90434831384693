import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    console.log("Modal wqeqweqwe");
  }

  open(event) {
    event.preventDefault();
    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("flex");
  }

  submitEnd(event) {
    if (event.detail.success) {
      this.close(event);
    }
  }

  close(event) {
    this.element.remove()
  }
}
