import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["input", "results"];

  connect() {
    this.debouncedSearch = debounce(this.search.bind(this), 300);
    this.inputTarget.addEventListener("input", this.debouncedSearch);
  }

  preventEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  search() {
    console.log("Searching...");
    const query = this.inputTarget.value.trim();
    const url = new URL(window.location.href);
    url.searchParams.set("query", query);

    fetch(url, { headers: { "Accept": "application/json" } })
      .then((response) => response.json())
      .then((data) => {
        this.resultsTarget.innerHTML = data.users;
      });
  }
}