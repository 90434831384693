import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "overlay"];

  connect() {
    this.menuTarget.classList.add("-translate-x-full");
    this.overlayTarget.classList.add("hidden");
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("-translate-x-full");
    this.menuTarget.classList.toggle("translate-x-0");
    this.overlayTarget.classList.toggle("hidden");
  }

  closeMenu() {
    this.menuTarget.classList.add("-translate-x-full");
    this.menuTarget.classList.remove("translate-x-0");
    this.overlayTarget.classList.add("hidden");
  }

  toggleLoginMenu() {
    const logoutButton = document.getElementById("logout-btn");
    logoutButton.classList.toggle("hidden");
  }
}
