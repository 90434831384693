import { Controller } from "@hotwired/stimulus"
import { put, post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["member"];

  dragStart(event) {
    console.log("start")
    event.target.style.opacity = "0.4";
    this.dragSrcEl = event.target;

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.target.innerHTML);
  }

  dragEnter(event) {
    console.log("enter")

    event.target.classList.add("over");

    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  }

  dragOver(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    return false;
  }

  dragLeave(event) {
    event.target.classList.remove("over");

    const trashBin = document.getElementById("trash-bin");
    if (trashBin) {
      trashBin.classList.remove("hidden");
      trashBin.classList.add("flex");
    }
  
    this.resetOpacity();
  }

  drop(event) {
    const container = event.target.closest("#party-slot");
    const party_id = container.dataset.partyId;
    const user_id = this.dragSrcEl.dataset.userId
    const filterParams = container.dataset.filterParams ? JSON.parse(container.dataset.filterParams) : {};

    event.stopPropagation();
    event.target.classList.remove("over");
    this.resetOpacity();
    const trashBin = document.getElementById("trash-bin");
    if (trashBin) {
      trashBin.classList.add("hidden");
    }

    this.dragSrcEl = null;

    console.log(filterParams)

    put(`/parties/${party_id}`, {
      body: JSON.stringify({ user_id: user_id, filterParams: filterParams }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })

    // fetch("/note_links", {
    //   method: "POST",
    //   credentials: "same-origin",
    //   headers: {
    //     Accept: "text/vnd.turbo-stream.html",
    //     "Content-Type": "application/json",
    //     "X-CSRF-Token": document.head.querySelector("[name='csrf-token']")
    //       .content,
    //   },
    //   body: JSON.stringify({
    //     source_id: this.dragSrcEl.dataset.id,
    //     target_id: event.target.dataset.id,
    //   }),
    // })
    //   .then((response) => response.text())
    //   .then(Turbo.renderStreamMessage);
  }

  allowDrop(event) {
    event.preventDefault();
  }

  dropInTrash(event) {
    event.preventDefault();
    console.log("Dropped in Trash!");

    const userId = this.dragSrcEl.dataset.userId;
    const raidId = event.target.dataset.raidId; 
    
    this.dragSrcEl.style.opacity = "0";

    setTimeout(() => {
      this.dragSrcEl.remove();
    }, 0);

    post(`/raids/${raidId}/remove_member`, {
      body: JSON.stringify({ user_id: userId }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })
  }

  dragEnd() {
    this.resetOpacity();
  }

  resetOpacity() {
    this.memberTargets.forEach((el) => {
      el.style.opacity = "1";
    });
  }

  connect(){
  }
}
