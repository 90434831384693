import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "input"];

  connect() {
    this.originalName = this.nameTarget.innerText.trim();
  }

  enableEditing() {
    this.nameTarget.classList.add("hidden");
    this.inputTarget.classList.remove("hidden");
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length);

    this.inputTarget.addEventListener("keydown", this.handleKeydown.bind(this));
    this.inputTarget.addEventListener("blur", this.save.bind(this));
  }

  save() {
    const newName = this.inputTarget.value.trim();
    if (newName === "" || newName === this.originalName) {
      this.cancelEditing();
      return;
    }

    fetch(`/parties/${this.inputTarget.dataset.partyId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content,
      },
      body: JSON.stringify({ party: { party_name: newName } }),
    })
      .then(response => response.json())
      .then(data => {
        this.nameTarget.innerText = data.party_name;
        this.cancelEditing();
      })
      .catch(error => console.error("Error:", error));
  }

  cancelEditing() {
    this.nameTarget.classList.remove("hidden");
    this.inputTarget.classList.add("hidden");

    this.inputTarget.removeEventListener("keydown", this.handleKeydown.bind(this));
    this.inputTarget.removeEventListener("blur", this.save.bind(this));
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      this.save();
    } else if (event.key === "Escape") {
      this.cancelEditing();
    }
  }
}
